<template>
  <v-row wrap no-gutters>
    <v-col cols="12" class="pb-3">
      <add-keys
        :key="`${step.id}-variables`"
        type="variables"
        :can-edit="canEdit"
        :data="properties.variables"
        @dataChanged="handleChange('variables', $event)"
      ></add-keys>
    </v-col>
  </v-row>
</template>

<script>
import addKeys from '../KeyList'
import StepMixin from './StepMixin'

export default {
  name: 'UnsetVarsStep',
  components: {
    addKeys
  },
  mixins: [StepMixin],
  created() {
    if (!this.properties?.variables || !Array.isArray(this.properties.variables)) 
      this.properties = { variables: [] }
  }
}
</script>
